export interface Page {
  pageName: string;
  pageNamePrefix?: string;
  pathPattern: string;
  pageId: number;
}

export interface Pages {
  [pathPattern: string]: Page;
}
export enum PageName {
  LOGIN = 'Login',
  OVERVIEW = 'Overview',
  ROBOT_DASHBOARD = 'Robot Dashboard',
  MACHINE_LIST = 'Machines',
  MACHINE_DETAILS = 'Machine Details',
  SITE_LIST = 'Sites',
  SITE_DETAILS = 'Site Details',
  EVENTS = 'Events',
  NOTIFICATION_LIST = 'Notifications',
  SERVICE = 'Service',
  PERMISSIONS = 'Permissions',
  PRIVACY_POLICY = 'Privacy Policy',
}

export enum PageNamePrefix {
  MACHINE_DETAILS = 'Machines:',
  SITE_DETAILS = 'Sites:',
}

export enum PathPattern {
  LOGIN = '/login',
  OVERVIEW = '/overview',
  ROBOT_DASHBOARD = '/robot-dashboard',
  MACHINE_LIST = '/devices',
  MACHINE_DETAILS = '/devices/:id',
  SITE_LIST = '/sites',
  SITE_DETAILS = '/sites/:id',
  EVENTS = '/events',
  NOTIFICATION_LIST = '/notifications',
  SERVICE = '/service',
  PERMISSIONS = '/users',
  PRIVACY_POLICY = '/privacy-policy',
}

export const pages: Pages = {
  [PathPattern.LOGIN]: {
    pageName: PageName.LOGIN,
    pathPattern: PathPattern.LOGIN,
    pageId: 1,
  },
  [PathPattern.OVERVIEW]: {
    pageName: PageName.OVERVIEW,
    pathPattern: PathPattern.OVERVIEW,
    pageId: 2,
  },
  [PathPattern.MACHINE_LIST]: {
    pageName: PageName.MACHINE_LIST,
    pathPattern: PathPattern.MACHINE_LIST,
    pageId: 3,
  },
  [PathPattern.MACHINE_DETAILS]: {
    pageName: PageName.MACHINE_DETAILS,
    pageNamePrefix: PageNamePrefix.MACHINE_DETAILS,
    pathPattern: PathPattern.MACHINE_DETAILS,
    pageId: 4,
  },
  [PathPattern.SITE_LIST]: {
    pageName: PageName.SITE_LIST,
    pathPattern: PathPattern.SITE_LIST,
    pageId: 5,
  },
  [PathPattern.SITE_DETAILS]: {
    pageName: PageName.SITE_DETAILS,
    pageNamePrefix: PageNamePrefix.SITE_DETAILS,
    pathPattern: PathPattern.SITE_DETAILS,
    pageId: 6,
  },
  [PathPattern.EVENTS]: {
    pageName: PageName.EVENTS,
    pathPattern: PathPattern.EVENTS,
    pageId: 7,
  },
  [PathPattern.NOTIFICATION_LIST]: {
    pageName: PageName.NOTIFICATION_LIST,
    pathPattern: PathPattern.NOTIFICATION_LIST,
    pageId: 8,
  },
  [PathPattern.SERVICE]: {
    pageName: PageName.SERVICE,
    pathPattern: PathPattern.SERVICE,
    pageId: 9,
  },
  [PathPattern.PERMISSIONS]: {
    pageName: PageName.PERMISSIONS,
    pathPattern: PathPattern.PERMISSIONS,
    pageId: 10,
  },
  [PathPattern.PRIVACY_POLICY]: {
    pageName: PageName.PRIVACY_POLICY,
    pathPattern: PathPattern.PRIVACY_POLICY,
    pageId: 11,
  },
  [PathPattern.ROBOT_DASHBOARD]: {
    pageName: PageName.ROBOT_DASHBOARD,
    pathPattern: PathPattern.ROBOT_DASHBOARD,
    pageId: 12,
  },
};
