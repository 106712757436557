// Split to new file to avoid issue cycle dependency between file: routes and pages/components
export const RoutePaths = {
  LOGIN: '/login',
  ROOT: '/',
  CALLBACK: '/callback',
  INIT: '/init',
  OVERVIEW: '/overview',
  ROBOT_DASHBOARD: '/robot-dashboard',
  MACHINES: '/devices',
  MACHINE_DETAILS: '/devices/:id',
  EVENTS: '/events',
  SITES: '/sites',
  SERVICE: '/service',
  SITE_DETAILS: '/sites/:id',
  NOTIFICATIONS: '/notifications',
  USERS: '/users',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_CONDITIONS: '/terms-and-conditions',
  CONTRACT_OBLIGATION: '/contract-obligation',
  LICENSE_AGREEMENT: '/license-agreement',
  UNAUTHORIZED: '/unauthorized',
};
