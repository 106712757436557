import { ThemeConfig } from 'antd';
import { DefaultTheme } from 'styled-components';

type DefaultThemeBase = Omit<DefaultTheme, 'fontStyles'>;

export const ThemeConstants = {
  NARROW_CONTAINER: {
    MAX_WIDTH: 1056,
  },
  WIDE_CONTAINER: {
    MAX_WIDTH: 2297,
  },
  SIDEBAR: {
    WIDTH: {
      FULL: 210,
      COLLAPSED: 56,
    },
  },
  DRAWER: {
    WIDTH: {
      XS: 430,
      SM: 608,
      MD: 752,
      LG: '60vw',
    },
  },
  MD_GAP: 20,
  BASE_GAP: 24,
  XL_GAP: 32,
  SPACING: {
    XXS: '4px',
    XS: '8px',
    SM: '12px',
    BASE: '16px',
    MD: '20px',
    LG: '24px',
    XL: '32px',
    XXL: '48px',
  },
  PICTURE_ICON_COLUMN_WIDTH: '9%', // space for the icon to be shown, if not it's take up enough space automatically
};

const headerContentBase = `
  margin: auto;
  padding: ${ThemeConstants.SPACING.XL} ${ThemeConstants.SPACING.XXL};
`;

const bodyContentBase = `
  margin: auto;
  padding: 0 ${ThemeConstants.SPACING.XXL} ${ThemeConstants.SPACING.XL};
`;

const themeBase: DefaultThemeBase = {
  constants: {
    baseGap: ThemeConstants.BASE_GAP,
    narrowContainer: {
      maxWidth: ThemeConstants.NARROW_CONTAINER.MAX_WIDTH,
    },
    wideContainer: {
      maxWidth: ThemeConstants.WIDE_CONTAINER.MAX_WIDTH,
    },
    sideBar: {
      width: {
        full: ThemeConstants.SIDEBAR.WIDTH.FULL,
        collapsed: ThemeConstants.SIDEBAR.WIDTH.COLLAPSED,
      },
    },
    drawer: {
      width: {
        xs: ThemeConstants.DRAWER.WIDTH.XS,
        sm: ThemeConstants.DRAWER.WIDTH.SM,
        md: ThemeConstants.DRAWER.WIDTH.MD,
        lg: ThemeConstants.DRAWER.WIDTH.LG,
      },
    },
  },
  fonts: {
    heading1: 'ClanWebPro-Bold',
    heading2: 'ClanWebPro-NarrBlack',
    heading3: 'ClanWebPro-Bold',
    heading4: 'ClanWebPro-Bold',
    subHeading: 'ClanWebPro-Bold',
    p0: 'ClanWebPro-Medium',
    p1Bold: 'ClanWebPro-Bold',
    p1Medium: 'ClanWebPro-Medium',
    p1News: 'ClanWebPro-News',
    captionBold: 'ClanWebPro-Bold',
    captionMedium: 'ClanWebPro-Medium',
    captionNews: 'ClanWebPro-News',
    captionSmall: 'ClanWebPro-News',
    buttonM: 'ClanWebPro-Bold',
    redesign: {
      heading1: 'ClanWebPro-NarrBlack',
      heading2: 'ClanWebPro-NarrBlack',
      heading3: 'ClanWebPro-NarrBlack',
      heading4: 'ClanWebPro-NarrBlack',
      heading5: 'ClanWebPro-NarrBlack',
      sm: {
        normal: 'ClanWebPro-News',
        strong: 'ClanWebPro-Bold',
        delete: 'ClanWebPro-Bold',
      },
      base: {
        normal: 'ClanWebPro-News',
        strong: 'ClanWebPro-Medium',
        delete: 'ClanWebPro-Bold',
      },
      lg: {
        normal: 'ClanWebPro-News',
        strong: 'ClanWebPro-Bold',
        delete: 'ClanWebPro-Bold',
      },
      xl: {
        normal: 'ClanWebPro-News',
        strong: 'ClanWebPro-Bold',
        delete: 'ClanWebPro-Bold',
      },
    },
  },
  fontSizes: {
    heading1: '40px',
    heading2: '30px',
    heading3: '18px',
    heading4: '16px',
    subHeading: '24px',
    p0: '14px',
    p1: '12px',
    caption: '11px',
    captionSmall: '10px',
    buttonM: '14px',
    redesign: {
      heading1: '38px',
      heading2: '30px',
      heading3: '24px',
      heading4: '20px',
      heading5: '16px',
      sm: '12px',
      base: '14px',
      lg: '16px',
      xl: '20px',
    },
  },
  lineHeights: {
    heading1: '50px',
    heading2: '38px',
    heading3: '26px',
    heading4: '24px',
    subHeading: '32px',
    p0: '20px',
    p1: '16px',
    caption: '14px',
    captionSmall: '12px',
    buttonM: '20px',
    redesign: {
      heading1: '46px',
      heading2: '38px',
      heading3: '32px',
      heading4: '28px',
      heading5: '24px',
      sm: '20px',
      base: '22px',
      lg: '24px',
      xl: '28px',
    },
  },
  fontWeights: {
    heading1: 700,
    heading2: 900,
    heading3: 700,
    heading4: 700,
    subHeading: 700,
    p0: 500,
    p1Bold: 700,
    p1Medium: 500,
    p1News: 475,
    captionBold: 700,
    captionMedium: 700,
    captionNews: 700,
    captionSmall: 475,
    buttonM: 700,
    redesign: {
      heading1: 475,
      heading2: 475,
      heading3: 475,
      heading4: 475,
      heading5: 475,
      sm: 475,
      base: 475,
      lg: 475,
      xl: 475,
    },
  },
  colors: {
    white: 'var(--white-color)',
    black: 'var(--black-color)',

    grey: 'var(--grey)',
    brightGrey: 'var(--bright-grey)',
    darkGrey: 'var(--dark-grey)',
    grey200: 'var(--grey-200)',
    grey300: 'var(--grey-300)',
    grey400: 'var(--grey-400)',

    primary: 'var(--primary)',
    secondary: 'var(--secondary)',
    secondary100: 'var(--secondary-100)',
    secondary200: 'var(--secondary-200)',

    semanticError: 'var(--semantic-error)',
    semanticWarning: 'var(--semantic-warning)',
    semanticSuccess: 'var(--semantic-success)',
    semanticInfo: 'var(--semantic-info)',

    /* TODO: These colors are not in the new colors definitions. */
    /* We need to clarify with the designers */
    brighterGrey: 'var(--brighter-grey)',
    celadonGreen: 'var(--celadon-green-color)',
    lightCeladonGreen: 'var(--light-celadon-green-color)',
    primaryHover: 'var(--primary-hover)',
    focusBorder: 'var(--focus-border)',
    brightBlue: 'var(--bright-blue)',
    green: 'var(--green)',
    gray85: 'var(--gray-85)',

    green100: 'var(--green-100)',
    karcherYellow100: 'var(--karcher-yellow-100)',
    red100: 'var(--red-100)',
    blue100: 'var(--blue-100)',
    blueLink: 'var(--blue-link)',
    gray100: 'var(--gray-100)',
  },
  borderRadiuses: {
    xs: '2px',
    sm: '4px',
    base: '6px',
    lg: '8px',
    pillSmall: '16px',
    chartBar: '72px',
    pill: '100px',
  },
  spacing: {
    xxs: ThemeConstants.SPACING.XXS,
    xs: ThemeConstants.SPACING.XS,
    sm: ThemeConstants.SPACING.SM,
    base: ThemeConstants.SPACING.BASE,
    md: ThemeConstants.SPACING.MD,
    lg: ThemeConstants.SPACING.LG,
    xl: ThemeConstants.SPACING.XL,
    xxl: ThemeConstants.SPACING.XXL,
  },
  transitionTimingFunctions: {
    // See https://easings.net/#easeOutBack
    easeOutBack: 'cubic-bezier(0.34, 1.56, 0.64, 1)',
  },
  mixins: {
    truncateText: `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    `,
    dropShadow: `
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    `,
    flexCenter: `
    display: flex;
    justify-content: center;
    align-items: center;
    `,
    layout: {
      addHeaderContentClass: blockName => `
        .${blockName}__header-content {
          ${headerContentBase}
        }
      `,
      addBodyContentClass: blockName => `
        .${blockName}__body-content {
          ${bodyContentBase}
        }
      `,
      addContainerClasses: blockName => `
        .${blockName}__container--narrow {
          max-width: ${ThemeConstants.NARROW_CONTAINER.MAX_WIDTH}px;
          margin-left: auto;
          margin-right: auto;
          padding: 0 ${ThemeConstants.BASE_GAP}px;
        }

        .${blockName}__container--wide {
          max-width: ${ThemeConstants.WIDE_CONTAINER.MAX_WIDTH}px;
          margin-left: auto;
          margin-right: auto;
          padding: 0 ${ThemeConstants.BASE_GAP}px;
        }

        .${blockName}__container--fluid {
          margin-left: auto;
          margin-right: auto;
          padding: 0 ${ThemeConstants.BASE_GAP}px;
        }

        .${blockName}__container--bottom-margin {
          margin-bottom: ${ThemeConstants.BASE_GAP}px;
        }
      `,
    },
  },
};

export const theme: DefaultTheme = {
  ...themeBase,
  fontStyles: {
    heading1: `
    font-family: ${themeBase.fonts.heading1};
    font-size: ${themeBase.fontSizes.heading1};
    line-height: ${themeBase.lineHeights.heading1};
    font-weight: ${themeBase.fontWeights.heading1};
    `,
    heading2: `
    font-family: ${themeBase.fonts.heading2};
    font-size: ${themeBase.fontSizes.heading2};
    line-height: ${themeBase.lineHeights.heading2};
    font-weight: ${themeBase.fontWeights.heading2};
    `,
    heading3: `
    font-family: ${themeBase.fonts.heading3};
    font-size: ${themeBase.fontSizes.heading3};
    line-height: ${themeBase.lineHeights.heading3};
    font-weight: ${themeBase.fontWeights.heading3};
    `,
    heading4: `
    font-family: ${themeBase.fonts.heading4};
    font-size: ${themeBase.fontSizes.heading4};
    line-height: ${themeBase.lineHeights.heading4};
    font-weight: ${themeBase.fontWeights.heading4};
    `,
    subHeading: `
    font-family: ${themeBase.fonts.subHeading};
    font-size: ${themeBase.fontSizes.subHeading};
    line-height: ${themeBase.lineHeights.subHeading};
    font-weight: ${themeBase.fontWeights.subHeading};
    `,
    p0: `
    font-family: ${themeBase.fonts.p0};
    font-size: ${themeBase.fontSizes.p0};
    line-height: ${themeBase.lineHeights.p0};
    font-weight: ${themeBase.fontWeights.p0};
    `,
    p1: {
      bold: `
      font-family: ${themeBase.fonts.p1Bold};
      font-size: ${themeBase.fontSizes.p1};
      line-height: ${themeBase.lineHeights.p1};
      font-weight: ${themeBase.fontWeights.p1Bold};
    `,
      medium: `
      font-family: ${themeBase.fonts.p1Medium};
      font-size: ${themeBase.fontSizes.p1};
      line-height: ${themeBase.lineHeights.p1};
      font-weight: ${themeBase.fontWeights.p1Medium};
    `,
      news: `
      font-family: ${themeBase.fonts.p1News};
      font-size: ${themeBase.fontSizes.p1};
      line-height: ${themeBase.lineHeights.p1};
      font-weight: ${themeBase.fontWeights.p1News};
    `,
    },
    caption: {
      bold: `
      font-family: ${themeBase.fonts.captionBold};
      font-size: ${themeBase.fontSizes.caption};
      line-height: ${themeBase.lineHeights.caption};
      font-weight: ${themeBase.fontWeights.captionBold};
    `,
      medium: `
      font-family: ${themeBase.fonts.captionMedium};
      font-size: ${themeBase.fontSizes.caption};
      line-height: ${themeBase.lineHeights.caption};
      font-weight: ${themeBase.fontWeights.captionMedium};
    `,
      news: `
      font-family: ${themeBase.fonts.captionNews};
      font-size: ${themeBase.fontSizes.caption};
      line-height: ${themeBase.lineHeights.caption};
      font-weight: ${themeBase.fontWeights.captionNews};
    `,
    },
    captionSmall: `
    font-family: ${themeBase.fonts.captionSmall};
    font-size: ${themeBase.fontSizes.captionSmall};
    line-height: ${themeBase.lineHeights.captionSmall};
    font-weight: ${themeBase.fontWeights.captionSmall};
    `,
    buttonM: `
    font-family: ${themeBase.fonts.buttonM};
    font-size: ${themeBase.fontSizes.buttonM};
    line-height: ${themeBase.lineHeights.buttonM};
    font-weight: ${themeBase.fontWeights.buttonM};
    `,
    redesign: {
      heading1: `
      font-family: ${themeBase.fonts.redesign.heading1};
      font-size: ${themeBase.fontSizes.redesign.heading1};
      line-height: ${themeBase.lineHeights.redesign.heading1};
      font-weight: ${themeBase.fontWeights.redesign.heading1};
      `,
      heading2: `
      font-family: ${themeBase.fonts.redesign.heading2};
      font-size: ${themeBase.fontSizes.redesign.heading2};
      line-height: ${themeBase.lineHeights.redesign.heading2};
      font-weight: ${themeBase.fontWeights.redesign.heading2};
      `,
      heading3: `
      font-family: ${themeBase.fonts.redesign.heading3};
      font-size: ${themeBase.fontSizes.redesign.heading3};
      line-height: ${themeBase.lineHeights.redesign.heading3};
      font-weight: ${themeBase.fontWeights.redesign.heading3};
      `,
      heading4: `
      font-family: ${themeBase.fonts.redesign.heading4};
      font-size: ${themeBase.fontSizes.redesign.heading4};
      line-height: ${themeBase.lineHeights.redesign.heading4};
      font-weight: ${themeBase.fontWeights.redesign.heading4};
      `,
      heading5: `
      font-family: ${themeBase.fonts.redesign.heading5};
      font-size: ${themeBase.fontSizes.redesign.heading5};
      line-height: ${themeBase.lineHeights.redesign.heading5};
      font-weight: ${themeBase.fontWeights.redesign.heading5};
      `,
      sm: {
        normal: `
        font-family: ${themeBase.fonts.redesign.sm.normal};
        font-size: ${themeBase.fontSizes.redesign.sm};
        line-height: ${themeBase.lineHeights.redesign.sm};
        font-weight: ${themeBase.fontWeights.redesign.sm};
        `,
        strong: `
        font-family: ${themeBase.fonts.redesign.sm.strong};
        font-size: ${themeBase.fontSizes.redesign.sm};
        line-height: ${themeBase.lineHeights.redesign.sm};
        font-weight: ${themeBase.fontWeights.redesign.sm};
        `,
        delete: `
        font-family: ${themeBase.fonts.redesign.sm.delete};
        font-size: ${themeBase.fontSizes.redesign.sm};
        line-height: ${themeBase.lineHeights.redesign.sm};
        font-weight: ${themeBase.fontWeights.redesign.sm};
        text-transform: uppercase;
        `,
      },
      base: {
        normal: `
        font-family: ${themeBase.fonts.redesign.base.normal};
        font-size: ${themeBase.fontSizes.redesign.base};
        line-height: ${themeBase.lineHeights.redesign.base};
        font-weight: ${themeBase.fontWeights.redesign.base};
        `,
        strong: `
        font-family: ${themeBase.fonts.redesign.base.strong};
        font-size: ${themeBase.fontSizes.redesign.base};
        line-height: ${themeBase.lineHeights.redesign.base};
        font-weight: ${themeBase.fontWeights.redesign.base};
        `,
        delete: `
        font-family: ${themeBase.fonts.redesign.base.delete};
        font-size: ${themeBase.fontSizes.redesign.base};
        line-height: ${themeBase.lineHeights.redesign.base};
        font-weight: ${themeBase.fontWeights.redesign.base};
        text-transform: uppercase;
        `,
      },
      lg: {
        normal: `
        font-family: ${themeBase.fonts.redesign.lg.normal};
        font-size: ${themeBase.fontSizes.redesign.lg};
        line-height: ${themeBase.lineHeights.redesign.lg};
        font-weight: ${themeBase.fontWeights.redesign.lg};
        `,
        strong: `
        font-family: ${themeBase.fonts.redesign.lg.strong};
        font-size: ${themeBase.fontSizes.redesign.lg};
        line-height: ${themeBase.lineHeights.redesign.lg};
        font-weight: ${themeBase.fontWeights.redesign.lg};
        `,
        delete: `
        font-family: ${themeBase.fonts.redesign.lg.delete};
        font-size: ${themeBase.fontSizes.redesign.lg};
        line-height: ${themeBase.lineHeights.redesign.lg};
        font-weight: ${themeBase.fontWeights.redesign.lg};
        text-transform: uppercase;
        `,
      },
      xl: {
        normal: `
        font-family: ${themeBase.fonts.redesign.xl.normal};
        font-size: ${themeBase.fontSizes.redesign.xl};
        line-height: ${themeBase.lineHeights.redesign.xl};
        font-weight: ${themeBase.fontWeights.redesign.xl};
        `,
        strong: `
        font-family: ${themeBase.fonts.redesign.xl.strong};
        font-size: ${themeBase.fontSizes.redesign.xl};
        line-height: ${themeBase.lineHeights.redesign.xl};
        font-weight: ${themeBase.fontWeights.redesign.xl};
        `,
        delete: `
        font-family: ${themeBase.fonts.redesign.xl.delete};
        font-size: ${themeBase.fontSizes.redesign.xl};
        line-height: ${themeBase.lineHeights.redesign.xl};
        font-weight: ${themeBase.fontWeights.redesign.xl};
        text-transform: uppercase;
        `,
      },
    },
  },
};

export const antDesignToken: ThemeConfig['token'] = {
  borderRadius: 0,
  colorBorder: theme.colors.darkGrey,
  controlHeight: 32,
  fontFamily: theme.fonts.p1News,
  fontSize: 12,
  colorLinkHover: theme.colors.black,
  colorPrimaryHover: theme.colors.black,
};

export const antDesignComponentTokens: ThemeConfig['components'] = {
  Button: {
    fontFamily: theme.fonts.buttonM,
    defaultShadow: '',
    primaryShadow: '',
    textHoverBg: '',
  },
  Radio: {
    colorPrimary: theme.colors.white,
    colorPrimaryHover: theme.colors.black,
  },
  Checkbox: {
    colorPrimary: theme.colors.black,
    colorPrimaryHover: theme.colors.black,
  },
  Switch: {
    colorPrimary: theme.colors.black,
    colorPrimaryHover: theme.colors.black,
  },
  Select: {
    colorPrimary: theme.colors.focusBorder,
    colorPrimaryHover: theme.colors.focusBorder,
    fontFamily: theme.fonts.p1Medium,
  },
  Table: {
    headerBg: theme.colors.grey,
    headerSortHoverBg: theme.colors.grey,
    rowSelectedBg: theme.colors.primary,
    rowSelectedHoverBg: theme.colors.primary,
  },
  Tabs: {
    cardPadding: '8px 16px',
    fontFamily: theme.fonts.p1Medium,
    fontSize: 14,
  },
  Input: {
    borderRadius: 0,
  },
  DatePicker: {
    fontFamily: theme.fonts.p1Medium,
    fontSize: 14,
    cellHoverWithRangeBg: theme.colors.brightGrey,
    colorPrimary: theme.colors.black,
  },
  Upload: {
    colorPrimary: theme.colors.grey,
  },
  Segmented: {
    trackBg: theme.colors.white,
    itemColor: theme.colors.black,
    itemSelectedBg: theme.colors.black,
    itemSelectedColor: theme.colors.white,
    trackPadding: 0,
    controlHeight: 30,
  },
};
