import React from 'react';
import { Navigate } from 'react-router-dom';
import { IRoute } from '../app/cross-cutting-concerns/routing/interfaces/Routing.types';
import { CleaningReportListPage } from '../app/pages/CleaningReportListPage/CleaningReportListPage';
import { UserListPage } from '../app/pages/UserListPage/UserListPage';
import { Feature } from './feature-flags';
import { RoutePaths } from './route-paths';
import { Permission } from './permissions';
import { MachineListPage } from 'app/pages/MachineListPage/MachineListPage';
import { SiteListPage } from 'app/pages/SiteListPage/SiteListPage';
import { ServicePage } from 'app/pages/ServicePage/ServicePage';
import { OverviewPage } from 'app/pages/OverviewPage/OverviewPage';
import { NoRouteFoundPage } from 'app/pages/NoRouteFoundPage/NoRouteFoundPage';
import { LoginPage } from 'app/pages/LoginPage/LoginPage';
import { NotificationList } from 'app/modules/notification/notification-list/components/NotificationList/NotificationList';
import { PrivacyPolicyPage } from 'app/pages/PrivacyPolicyPage/PrivacyPolicyPage';
import { TermsAndConditionsPage } from 'app/pages/TermsAndConditionsPage/TermsAndConditionsPage';
import { ContractObligationPage } from 'app/pages/ContractObligationPage/ContractObligationPage';
import { LicenseAgreementPage } from 'app/pages/LicenseAgreementPage/LicenseAgreementPage';
import { InitPage } from 'app/pages/InitPage/InitPage';
import { RobotDashboardPage } from 'app/pages/RobotDashboardPage/RobotDashboardPage';
import { CURRENT_LICENSE_AGREEMENT_VERSION } from 'app/modules/terms-conditions/constants';
import { LicenseAgreementVersions } from 'app/modules/terms-conditions/interfaces/TermsAndConditions.types';
import { UnauthorizedPage } from 'app/pages/UnauthorizedPage/UnauthorizedPage';

const isLicenseAgreementEnabled = CURRENT_LICENSE_AGREEMENT_VERSION !== LicenseAgreementVersions.V0;

export const privateRoutes: IRoute[] = [
  {
    index: true,
    element: <Navigate to={RoutePaths.OVERVIEW} replace={true} />,
    permissions: [],
  },
  {
    path: RoutePaths.OVERVIEW,
    element: <OverviewPage />,
    permissions: [],
  },
  {
    path: RoutePaths.MACHINES,
    element: <MachineListPage />,
    permissions: [],
  },
  {
    path: RoutePaths.MACHINE_DETAILS,
    element: <MachineListPage />,
    permissions: [],
  },
  {
    path: RoutePaths.EVENTS,
    element: <CleaningReportListPage />,
    permissions: [],
  },
  {
    path: RoutePaths.SITE_DETAILS,
    element: <SiteListPage />,
    permissions: [],
  },
  {
    path: RoutePaths.SITES,
    element: <SiteListPage />,
    permissions: [],
  },
  {
    path: RoutePaths.ROBOT_DASHBOARD,
    element: <RobotDashboardPage />,
    permissions: [],
    featureFlag: Feature.ROBOT_INTEGRATION,
  },
];

export const privateRoutesWithoutLayout: IRoute[] = [
  {
    path: RoutePaths.INIT,
    element: <InitPage />,
    permissions: [],
  },
  {
    path: RoutePaths.CONTRACT_OBLIGATION,
    element: <ContractObligationPage />,
    permissions: [],
  },
  ...(isLicenseAgreementEnabled
    ? [
        {
          path: RoutePaths.LICENSE_AGREEMENT,
          element: <LicenseAgreementPage />,
          permissions: [],
        },
      ]
    : []),
];

export const termsAndConditionRoutes: IRoute[] = [
  {
    path: RoutePaths.TERMS_CONDITIONS,
    element: <TermsAndConditionsPage />,
    permissions: [],
  },
];

export const publicRoutes: IRoute[] = [
  {
    path: RoutePaths.LOGIN,
    element: <LoginPage />,
    permissions: [],
  },
  {
    path: RoutePaths.PRIVACY_POLICY,
    element: <PrivacyPolicyPage />,
    permissions: [],
  },
  {
    path: RoutePaths.UNAUTHORIZED,
    element: <UnauthorizedPage />,
    permissions: [],
  },
  // NoRouteFoundPage handles routes /logout, /callback and any route, that is not defined
  {
    path: '*',
    element: <NoRouteFoundPage />,
    permissions: [],
  },
];

export const privateNotificationRoutes: IRoute[] = [
  {
    path: RoutePaths.NOTIFICATIONS,
    element: <NotificationList />,
    permissions: [],
    featureFlag: Feature.NOTIFICATIONS,
  },
];

export const privateServiceRoutes: IRoute[] = [
  {
    path: RoutePaths.SERVICE,
    element: <ServicePage />,
    permissions: [],
    featureFlag: Feature.SERVICE,
  },
];

export const privateUserRoutes: IRoute[] = [
  {
    path: RoutePaths.USERS,
    element: <UserListPage />,
    permissions: [Permission.Permissions.READ],
    featureFlag: Feature.USER_LIST,
  },
];
